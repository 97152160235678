import EmpleadoTable from "./empleado.table";
import { ScanSyncType } from "../constants";
import { TipoAccion } from "../models/tipo-accion";

export class ScanAddDto {
    id?: number;
    data: string;
    timestamp: number;
    sync: ScanSyncType;
    tipo_accion: TipoAccion = TipoAccion.AUTOMATICO;
    turno_id: number = null;
    observation: string = '';
}

export class ScanTable extends ScanAddDto {    
    id: number;
    empleado?: EmpleadoTable;
}