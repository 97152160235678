import Dexie, { EntityTable, liveQuery, Observable } from "dexie";
import { notificacionServicio } from "../services/notificacion.servicio";
import EmpleadoTable from "./empleado.table";
import { ScanTable, ScanAddDto } from "./scan.table";
import { TipoAccion } from "../models/tipo-accion";
import { comercioServicio } from "../services/comercio.servicio";
import { Registro } from "../models/registro";
import { registroServicio } from "../services/registro.servicio";
import { ScanSyncType } from "../constants";
import { Empleado } from "../models/empleado";
import TurnosTable from "./turnos.table";
import { turnoServicio } from "../services/turno.servicio";

export default class FichajeDB extends Dexie {
	empleados!: EntityTable<EmpleadoTable, "id">;
	scans!: EntityTable<ScanTable, "id">;
	turnos!: EntityTable<TurnosTable, "id">;

	constructor(tenant:string) {
		super("FichajeDB_"+tenant);
		this.version(3).stores({
			empleados: "++id,num_doc,nombre,apellido",
			scans: "++id,data,timestamp,sync,tipo_accion,turno_id",
			turnos: "++id,name,hora_ingreso,hora_egreso,minutos_tolerancia",
		});
		this.empleados.mapToClass(EmpleadoTable);
		this.scans.mapToClass(ScanTable);
		this.turnos.mapToClass(TurnosTable);
	}

	async cargarTurnos(): Promise<void> {
		try {
			await turnoServicio.loadTurnosDesdeApi();
			console.info("se actualizaron los turnos");
		} catch (error) {
			notificacionServicio.error(
				"Error de conexión con el servidor (turnos/index): " + error
			);
		}
	}

	async addEmpleado(empleado: EmpleadoTable): Promise<number> {
		try {
			if (!this.empleados) {
				throw new Error(
					"La tabla empleados no está definida. Asegúrate de que la base de datos esté correctamente inicializada."
				);
			}
			return await db.empleados.add(empleado);
		} catch (error) {
			notificacionServicio.error(
				"Error al agregar el empleado a la base de datos"
			);
			throw error;
		}
	}

	async cargarEmpleados(empleados: EmpleadoTable[]): Promise<void> {
		try {
			console.info("paso *************************");
			await db.empleados.bulkPut(empleados);
		} catch (error) {
			notificacionServicio.error(
				"Error de conexión con el servidor (empleados/index)"
			);
		}
	}

	async obtenerTodosLosEmpleados(): Promise<EmpleadoTable[]> {
		try {
			if (!db.empleados) {
				throw new Error(
					"La tabla empleados no está definida. Asegúrate de que la base de datos esté correctamente inicializada."
				);
			}
			const empleados = await db.empleados.toArray();
			console.info("empleaduchosss", empleados);
			return empleados;
		} catch (error) {
			notificacionServicio.error(
				"Error al obtener los empleados de la base de datos"
			);
			throw error;
		}
	}

	async buscarEmpleadoPorNumDoc(
		num_doc: number | string
	): Promise<EmpleadoTable> {
		console.info("passooooo buscando num doc", num_doc);
		try {
			if (!db.empleados) {
				throw new Error(
					"La tabla empleados no está definida. Asegúrate de que la base de datos esté correctamente inicializada."
				);
			}
			if (typeof num_doc !== "string") {
				num_doc = String(num_doc);
			}
			return await db.empleados.where("num_doc").equals(num_doc).first();
		} catch (error) {
			notificacionServicio.error(
				"Error al buscar el empleado en la base de datos"
			);
			throw error;
		}
	}

	async addScan(
		scan: string,
		ingresoOrEgreso: TipoAccion = TipoAccion.AUTOMATICO,
		turno_id: number|null = null
	): Promise<number> {
		const scanString: ScanAddDto = {
			data: scan,
			timestamp: Date.now(), // Store timestamp as a number
			sync: ScanSyncType.PENDIENTE,
			tipo_accion: ingresoOrEgreso,
			//empleado: empleado
			turno_id: turno_id,
			observation: ''
		};
		const id = await this.scans.add(scanString);

		this.syncScan(id);

		return id;
	}

	async syncScan(id: number): Promise<boolean> {
		const scan = await this.scans.get(id);
		console.info("syncScan aca proibando la magicc sync:", scan.sync);
		if (
			scan.sync === ScanSyncType.SINCRONIZADO
		) {
			return false;
		}

		try {
			const scanConEmpleado = await this.updateScanWithEmpleado(id, scan);

			await this.updateScanWithFichaje(
				id,
				scanConEmpleado,
				scan.tipo_accion
			);
		} catch (error) {
			console.error("Error al sincronizar el scan", error);
			return false;
		}

		return true;
	}

	/**
	 * busca empleados de la base de datos y los agrega al registro de fichaje
	 * @param id
	 * @param scan
	 * @returns
	 */
	async updateScanWithEmpleado(
		id: number,
		scan: ScanAddDto
	): Promise<ScanTable> {
		console.info("enviando fichaje al servidor id", id);
		console.info("enviando fichaje al servidor scan", scan);
		const empleado = await this.buscarEmpleadoPorNumDoc(scan.data);
		console.info("el empleado encontrado para data es", empleado);
		if (!empleado) {
			console.error("empleado no encontrado");
		}

		const escaneoConEmpleado = { ...scan, ...{ id, empleado } };
		this.scans
			.where("id")
			.equals(id)
			.modify((value, ref) => {
				console.info("modificando scan", value, ref);
				ref.value = escaneoConEmpleado;
			});

		return escaneoConEmpleado;
	}

	async deleteScan(id: never): Promise<void> {
		return this.scans.delete(id);
	}

	async getTurnos(): Promise<TurnosTable[]> {
		return await this.turnos.toArray();
	}

	/** hace un fetch enviando un nuevo registro de fichaje */
	async updateScanWithFichaje(
		id: number,
		scan: ScanTable,
		accion: TipoAccion = TipoAccion.AUTOMATICO
	): Promise<void> {
		// si esta no esta online, no hago nada
		if (!navigator.onLine) {
			return;
		}


		if (accion === TipoAccion.AUTOMATICO) {
			const empleadoTable = await this.buscarEmpleadoPorNumDoc(scan.data)
			const empleado = new Empleado();
			empleado.loadEmpleadoTable(empleadoTable);
			accion = await empleado.sugerenciaIngresoEgreso();
			console.info("accion SUGERIDA ::::", accion);
		}
		const comercio = await comercioServicio.getComercioActual();
		const foto = null;
		const registro = new Registro(
			scan.empleado.id,
			comercio.id,
			new Date(scan.timestamp),
			accion,
			foto,
			scan.turno_id,
			scan.observation
		);
		console.info("el registro a enviar es", registro);

		await registroServicio
			.enviarRegistro(registro)
			.then(async (regResponse) => {
				console.info("Registro enviado recibiodo y guardado en paxapos", regResponse);
				const scanRegs = await this.scans.where("id").equals(id);
				const modify = await scanRegs.modify((value, ref) => {

					const accion: TipoAccion = regResponse.rta.Registro.DetalleEgresoRegistro?.id ? TipoAccion.EGRESO : TipoAccion.INGRESO;
					const newVal:ScanTable = {
						...value,
						...{
							tipo_accion: accion,
							sync: ScanSyncType.SINCRONIZADO,
							
						}


					};
					console.info("modificando scan", newVal, ref);
					ref.value = newVal;
				});
				console.info("modificados", modify);
			})
			.catch((error) => {
				console.error("Error al enviar el registro", error);
				this.scans
					.where("id")
					.equals(id)
					.modify((value, ref) => {
						ref.value = { ...value, sync: ScanSyncType.ERROR };
					});
			});
	}

	async updateScan(id: number, scan: ScanTable): Promise<number> {
		return this.scans
			.where("id")
			.equals(id)
			.modify((value, ref) => {
				console.info("modificando scan", value, ref);
				ref.value = scan;
			});
	}

	async getAllScans(): Promise<ScanTable[]> {
		return await this.scans.toArray();
	}

	async getCantScansIngresos(): Promise<number> {
		return await this.scans.filter((scan) => scan.tipo_accion === TipoAccion.INGRESO).count();
	}

	async getCantScansEgresos(): Promise<number> {
		return await this.scans.filter((scan) => scan.tipo_accion === TipoAccion.EGRESO).count();
	}

	async sendScansToServer(): Promise<void> {
		const scans = await this.getAllScans();
		if (scans.length > 0) {
			try {
				await this.scans.clear();
				notificacionServicio.exito("Scans sent successfully!");
			} catch (error) {
				notificacionServicio.error("Failed to send scans.");
			}
		}
	}

	scansLiveSync(orderBy = "timestamp", limit = 50): Observable<ScanTable[]> {
		console.info("inicializando liveSync");
		return liveQuery(() =>
			this.scans.orderBy(orderBy).reverse().limit(limit).toArray()
		);
	}

	async syncPendientes() {
		// si esta online, sincronizo los pendientes
		const scanPendientes = await this.scans
			.where("sync")
			.anyOf([ScanSyncType.PENDIENTE, ScanSyncType.ERROR])
			.toArray();
		scanPendientes.forEach((scan) => {
			setTimeout(() => {
				this.syncScan(scan.id);
			}, 2000);
		});
		console.info("entroo a actualizar scanPendientes", scanPendientes);
	}

	async deleteSincronizados() {
		const cantScansSincronizados = await this.scans.where("sync").equals(1).count();
		if( cantScansSincronizados >= 1 ){
			const eliminarSincronizados = await this.scans
				.where("sync")
				.equals(ScanSyncType.SINCRONIZADO)
				.delete();
	
			if(eliminarSincronizados > 0){
				console.info("se eliminaron los sincronizados");
			}else{
				console.error("no se eliminaron los sincronizados",eliminarSincronizados);
			}
		}else{
			console.info("no hay sincronizados para eliminar");
		}
	}

}

let db: FichajeDB = null;
export async function getFichajeTenantDB() {
	if (db) {
		return db
	}

	const comercio = await comercioServicio.getComercioActual()

	if ( !comercio ) {
		throw new Error("Para inicializar DB es necesario primero definir tenant del comercio");
	}

	db = new FichajeDB(comercio.tenant);

	db.cargarTurnos();

	return db;
}

