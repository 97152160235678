
import { RegistroResponse } from "../constants";
import { Empleado } from "../models/empleado";
import { Registro } from "../models/registro";
import { paxapos } from "./paxapos";

class RegistroServicio {


    public empleadoSiendoRegistrado: Empleado;
    public registroActual: Registro;

    public getRegistroActual(): Registro {
        return this.registroActual;
    }


    public async enviarRegistro(registro: Registro): Promise<RegistroResponse> {

        const data = { 
            method: "POST",
            body: registro.toSend()
        }
        console.info('data',data);
        return await paxapos.fetch('/fichaje/registros/add.json', data)
       
    }

    public limpiarRegistro() {
        this.empleadoSiendoRegistrado = null;
        this.registroActual = null;
    }
}


export const registroServicio = new RegistroServicio();