import { ipxDepartamento, ipxTurno } from "../models/empleado";
import { FaceLandmarkerResult } from "@paxapos/av-inputs";

export default class EmpleadoTable {
    id: number;
    nombre: string;
    apellido: string;
    num_doc: number|string;
    group_site_id: number;
    mail: string;
    pendienteDeSincronizacion: boolean;
    turno_id: number;
    Turno: ipxTurno[];
    Departamento: ipxDepartamento;

    detectionData: {
        descriptor?: FaceLandmarkerResult, 
        pic: Blob
    } = null

    loadEmpleado(json: EmpleadoTable) {
        this.pendienteDeSincronizacion = true;
        for (let key in json) {
            this[key] = json[key];
        }
    }
    setDetectionData( pic: Blob, descriptor?: FaceLandmarkerResult ) {
        this.detectionData = {descriptor, pic}
    }
}
