
import { paxapos } from "./paxapos";
import TurnosTable, { TurnoDia } from "../dbs/turnos.table";
import { getFichajeTenantDB } from "../dbs/fichaje.db";


export interface Turno {
    id: number;
    name: string;
    hora_ingreso: string;
    hora_egreso: string;
    minutos_tolerancia: number;
}

interface FichajeTurno {
    Turno: Turno;
    TurnosDia: TurnoDia[];
}

export interface TurnoResponseDto {
    fichajeTurnos: Array<FichajeTurno>
}

export class TurnoServicio {

    public createTurnoTable(ft: FichajeTurno): TurnosTable {

        return {...ft.Turno, TurnoDia: ft.TurnosDia}
    }

    public async loadTurnosDesdeApi(): Promise<TurnosTable[]> {
        const respuesta = await paxapos.fetch('/fichaje/fichaje_turnos/index.json') as TurnoResponseDto

        if ( respuesta.fichajeTurnos?.length > 0 ) {
            this.createTurnoTable(respuesta.fichajeTurnos[0])
            // vienen como empleado[i].Empleado debo aplanarlos
            const turnos = respuesta.fichajeTurnos.map( this.createTurnoTable )
            const fichajeDB = await getFichajeTenantDB()
            await fichajeDB.turnos.bulkPut(turnos)
            return turnos;
        }

        return []
    }
}


export const turnoServicio = new TurnoServicio();
