import { getDay, isWithinInterval, addMinutes } from "date-fns";
import { FaceLandmarkerResult, InputScanData } from "@paxapos/av-inputs";
import { empleadoServicio } from "../services/empleado.servicio";
import { TipoAccion } from "./tipo-accion";
import EmpleadoTable from "../dbs/empleado.table";


export interface ipxEmpleadoFetch {
    Empleado: ipxEmpleado
    Departamento: ipxDepartamento,
    Turno: ipxTurno[]
}

interface ipxEmpleado {
    id?: number;
    nombre?: string;
    apellido?: string;
    hora_ingreso?: Date; //TODO
    hora_egreso?: Date;  //TODO
    num_doc?: number|string;
    group_site_id?: number;
    mail?: string;
    telefono?: number;
    foto?: File;
}


export interface ipxDepartamento {
    id: number
    name: string
}

interface ipxTurnosDia {
    id: number,
    dia_semana: number,
    fecha_especifica: string
}


export interface ipxTurno {
    TurnosDia: ipxTurnosDia[]
    hora_egreso: string;
    hora_ingreso: string; 
    minutos_tolerancia: number
    name: string
}


export class EmpleadoDto {
    id?: number;
    nombre?: string;
    apellido?: string;
    hora_ingreso?: Date; //TODO
    hora_egreso?: Date;  //TODO
    num_doc?: number|string;
    group_site_id?: number;
    mail?: string;
    telefono?: number;
    foto?: File;

  
}

export class Empleado extends EmpleadoDto {
    Departamento: ipxDepartamento;
    Turno: ipxTurno[]
    


    pendienteDeSincronizacion?: boolean;


    detectionData: {
        descriptor?: FaceLandmarkerResult, 
        pic: Blob
    } = null


    constructor(json?: ipxEmpleadoFetch ) {
        super();
        if ( json ) {
            this.pendienteDeSincronizacion = true;
            for (let key in json.Empleado) {
                this[key] = json.Empleado[key];
            }
    
            this.Departamento = json.Departamento || null
            this.Turno = json.Turno || []
        }
    }

    loadEmpleadoTable(json: EmpleadoTable) {
        this.pendienteDeSincronizacion = true;
        for (let key in json) {
            this[key] = json[key];
        }

        this.Turno = json.Turno || []
    }


    loadEmpleado(json: Empleado) {
        this.pendienteDeSincronizacion = true;
        for (let key in json) {
            this[key] = json[key];
        }

        this.Departamento = json.Departamento || null
        this.Turno = json.Turno || []
    }

    setDetectionData( pic: Blob, descriptor?: FaceLandmarkerResult ) {
        this.detectionData = {descriptor, pic}
    }

    setFromScanData(scanData: InputScanData) {
        this.num_doc = scanData.data ? scanData.data.dni : scanData.text
        this.nombre = scanData.data?.nombre
        this.apellido = scanData.data?.apellido
    }

    async sugerenciaIngresoEgreso(): Promise<TipoAccion> {
        
        const today = new Date();
        const dayOfWeek = getDay(today) + 1 ;

        // busco a a ver si el empelado trabaja en el dia de hoy
        const turnoEncontrado = this.Turno?.find( (t) => {
            
            const dia = t.TurnosDia.find( (td) =>  {
                return td.dia_semana == dayOfWeek
            })
            
            return dia
        })
        
        if ( turnoEncontrado ) {
            // busco el horario para detectar si es INGRESO o EGRESO
            if ( this.isWithinThresholdHours( turnoEncontrado.hora_egreso, turnoEncontrado.minutos_tolerancia ) ) {
                return TipoAccion.EGRESO
            }

            if ( this.isWithinThresholdHours( turnoEncontrado.hora_ingreso, turnoEncontrado.minutos_tolerancia ) ) {

                return TipoAccion.INGRESO
            }
        }

        const nempleadoPresente = await empleadoServicio.empleadoPresente(this)

        if ( nempleadoPresente ) {
            return TipoAccion.EGRESO
        } else {
            return TipoAccion.INGRESO
        }

    }


    isWithinThresholdHours(hourminute, thresholdMinutes) {


        const [hour, minute] = hourminute.split(':');

        const now = new Date();
        const targetTime = new Date();
        targetTime.setHours(hour);
        targetTime.setMinutes(minute);
        const thresholdTimeMin = addMinutes(targetTime, -thresholdMinutes);
        const thresholdTimeMax = addMinutes(targetTime, thresholdMinutes);
        const interval = { start: thresholdTimeMin, end: thresholdTimeMax };
        return isWithinInterval(now, interval);
      }
}