import { format } from "date-fns"
import { TipoAccion } from "./tipo-accion"


export interface RegistroDto {
  empleado_id: number;
  group_site_id: number;
  fecha_hora: Date;
  ingreso_egreso: TipoAccion;
  foto?: Blob;
  turno_id?: number;
  observation?: string;
}

export class Registro implements RegistroDto{
 
  constructor(
    public empleado_id: number, 
    public group_site_id: number, 
    public fecha_hora: Date, 
    public ingreso_egreso: TipoAccion,
    public foto: Blob,
    public turno_id?: number,
    public observation?: string) {

      if (!empleado_id) throw new Error("Empleado ID es obligatorio")
      if (!group_site_id) throw new Error("group_site_id es obligatorio")
      if (!ingreso_egreso) throw new Error("ingreso_egreso es obligatorio")
    }
    
    toSend(): FormData {
      const data = new FormData()

      const formatedFecha = format(this.fecha_hora, 'yyyy-MM-dd HH:mm:ss')
      const formatedFechaName = format(this.fecha_hora, 'yyyy-MM-dd-HH-mm-ss')
      data.append('data[Registro][empleado_id]', String(this.empleado_id))
      data.append('data[Registro][group_site_id]', String(this.group_site_id))
      data.append('data[Registro][fecha_hora]', formatedFecha )
      data.append('data[Registro][ingreso_egreso]', this.ingreso_egreso)
      if ( this.observation ) {
        data.append('data[Registro][observation]', this.observation)
      }
      if ( this.turno_id ) {
        data.append('data[Registro][turno_id]', String(this.turno_id))
      }

      if ( this.foto ) {
        data.append('data[Registro][media_file]', this.foto, this.empleado_id+"_pic_"+formatedFechaName)
      }

      return data
    }
  public esIngreso(): boolean {
    return this.ingreso_egreso == TipoAccion.INGRESO;
  }

  public esEgreso(): boolean {
    return this.ingreso_egreso == TipoAccion.EGRESO;
  }
}
