import { Turno } from "../services/turno.servicio";


export interface TurnoDia {
    id: number;
    dia_semana: number;
    fecha_especifica: string;
    turno_id: number;
}


export default class TurnosTable implements Turno{
    id: number;
    name: string;
    hora_ingreso: string; //HH:MM:SS
    hora_egreso: string; //HH:MM:SS
    minutos_tolerancia: number;
    TurnoDia: TurnoDia[];
}