
import { FaceLandmarkerResult } from "@paxapos/av-inputs";
import { Empleado } from "../models/empleado";
import { notificacionServicio } from "./notificacion.servicio";
import { paxapos } from "./paxapos";
import { getAssetPath } from "@stencil/core";
import { getFichajeTenantDB } from "../dbs/fichaje.db";
import EmpleadoTable from "../dbs/empleado.table";


export class EmpleadoServicio {


    private landmarkModels: any = null;

    async loadLandMarkModelsFromComercio(comercio: string) {

        if (  this.landmarkModels ) {
            return this.landmarkModels
        }

        const resp = await fetch( getAssetPath(`/trained_face/${comercio}.json`) );
        if ( resp.ok ) {
            this.landmarkModels = await resp.json()
        } else {
            throw new Error("No se pudo cargar el modelo de reconocimiento facial")
        }

        return  this.landmarkModels;
    }
 
    public async setListadoEmpleados(listadoEmpleados: EmpleadoTable[]): Promise<void> {
        const fichajeDB = await getFichajeTenantDB();
        return await fichajeDB.cargarEmpleados(listadoEmpleados)


    }


    public async getListadoEmpleados(): Promise<EmpleadoTable[]> {
        const fichajeDB = await getFichajeTenantDB();

        const empleados =  await fichajeDB.obtenerTodosLosEmpleados();
        return empleados || []
    }

    /**
     * Verifico que el empleado no exista en la lista antes de incluírlo
     * La función es asíncrona para evitar que el usuario espere que se compelte el proceso
     * @param empleado 
     */
    public async agregarEmpleadoAListado(empleado: any): Promise<number> {
        const fichajeDB = await getFichajeTenantDB();
        return await fichajeDB.addEmpleado(empleado)
    
    }


    // @TODO: implementar esta funcion para que devuelva el empleado que se detecta
    async detectarEmpleado( descriptor: FaceLandmarkerResult ): Promise<Empleado> {
        console.info("detectarEmpleado con descriptor", descriptor)
        let empleado = null;

        return empleado
    }


    public async empleadoPresente(empleado: number|Empleado): Promise<Empleado> {
        const num_doc = typeof empleado == "number" ? empleado : empleado.num_doc
        const respuesta = await paxapos.fetch('/fichaje/registros/working.json?num_doc='+num_doc)
        if ( respuesta.registros?.length > 0 ) {
            return new Empleado( respuesta.registros[0].Empleado)
        } else {
            return null
        }
    }

    public async buscarEmpleadoPorNumDoc(num_doc: number): Promise<EmpleadoTable> {
        console.info('voy a buscar por api');
        const fichajeDB = await getFichajeTenantDB();
        let empleado = await fichajeDB.buscarEmpleadoPorNumDoc(num_doc);
        if ( empleado === undefined ) {
            empleado = await this.buscarEmpleadoPorApi(num_doc);
        }
        console.info('empleado ** **********',empleado);
        return empleado;
    }

    public async buscarEmpleadoPorApi(num_doc: number): Promise<EmpleadoTable | undefined> {
        try {
            const respuesta = await paxapos.fetch('/fichaje/empleados/num_doc/'+num_doc+'.json')
            if ( respuesta.empleado ) {
                return respuesta.empleado as EmpleadoTable
            }
        } catch (error) {
            if (typeof error === 'object') {
                console.info('error.message', error.message);
                if (error.message && error.message.includes('Failed to fetch')) {
                    // Handle CORS error
                    return undefined;
                }else{
                    notificacionServicio.error("Error de CORS: No se pudo conectar con el servidor (empleados/num_doc)");
                }
            } 
        }        
    }


    public async loadListadoEmpleadosDesdeApi(): Promise<EmpleadoTable[]> {
        try {
            const respuesta = await paxapos.fetch('/fichaje/empleados/index.json')
    
            if ( respuesta.empleados?.length > 0 ) {
                // vienen como empleado[i].Empleado debo aplanarlos
                const newEmps: EmpleadoTable[] = respuesta.empleados.map( e => new Empleado(e))
                this.setListadoEmpleados(newEmps)

                return newEmps
            }
        } catch (error) {
            notificacionServicio.error("Error de conexión con el servidor (empleados/index)")
        }
        return []
    }



    public async registrarNuevoEmpleado( formData: FormData ): Promise<any> {

        let empleadoJson = {
            method: "POST",
            body: formData
        }
        const nuevoempleado = await paxapos.fetch("/fichaje/empleados/add.json", empleadoJson) as Empleado
        this.agregarEmpleadoAListado(nuevoempleado)
        return nuevoempleado
    }


}


export const empleadoServicio = new EmpleadoServicio();
